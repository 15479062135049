import React from "react";
import styled, { keyframes } from "styled-components";

const commonStyle = {
  margin: "auto",
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
};

const sizeMap = {
  small: "30px",
  default: "40px",
  large: "50px",
};

const spinnerRotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingContainer = styled.div`
  width: ${(props) => sizeMap[props.size] || sizeMap["default"]};
  height: ${(props) => sizeMap[props.size] || sizeMap["default"]};
  border-radius: 50%;
  border: ${(props) => props.thickness || "4px"} solid transparent;
  border-top: ${(props) => props.thickness || "4px"} solid
    ${(props) => props.color || "#3563E9"};
  animation: ${spinnerRotate} ${(props) => props.speed || 1}s linear infinite;
`;

const Loading = ({ style = commonStyle, color, speed, size, thickness }) => {
  return (
    <LoadingContainer
      style={style}
      color={color}
      speed={speed}
      size={size}
      thickness={thickness}
    />
  );
};

export default Loading;
