import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getMessaging, getToken } from "firebase/messaging";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBNh758vHCGZiI5TrKUo8Feq3wWO3rlLQg",
  authDomain: "coordifydb.firebaseapp.com",
  projectId: "coordifydb",
  storageBucket: "coordifydb.appspot.com",
  messagingSenderId: "612835156028",
  appId: "1:612835156028:web:747bdd136a1475c4aaa339",
  measurementId: "G-ZFKJPXB62Z",
};

export const generateToken = async () => {
  const permission = await Notification.requestPermission();
  // console.log(permission);
  if (permission === "granted") {
    const token = await getToken(messaging, {
      vapidKey:
        "BPHaHjZ0yZ4OQYYCsdQpUVRiuEqGZ8QIvlixN_tk7p1PJdFmr4xpaWPEF0LbFVmSvUZ5eN41D6W-BQUqJtlLudc",
    });
    // console.log(token);
  }
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db;
export const messaging = getMessaging(app);
export const auth = getAuth(app);
export const imgDB = getStorage(app);
