import { create } from "zustand";

const UserState = create((set) => ({
  user: {},
  setLoggedUser: (user) => set({ user: user }),
  orgId: 0,
  setOrganizationId: (orgId) => set({ orgId: orgId }),
}));

export default UserState;
