import "./App.css";
import { Route, Routes } from "react-router-dom";
import { AuthContextProvider } from "./context/AuthContext";
import ProtectedRoute from "./Components/ProtectedRoute";
import { useEffect, Suspense, lazy } from "react";
import { onMessage } from "firebase/messaging";
import { generateToken, messaging } from "./firebase";
import toast, { Toaster } from "react-hot-toast";
import Loading from "./Components/Loading/Loading";
import Aos from "aos";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    Aos.init(
      {
        duration: 250,
        once: true,
      },
      []
    );
  });

  useEffect(() => {
    generateToken();
    onMessage(messaging, (playload) => {
      console.log(playload);
      toast(playload.notification.body);
    });
  }, []);

  const LogIn = lazy(() => import("./Pages/auth/LogIn"));
  const SignUp = lazy(() => import("./Pages/auth/SignUp"));
  const DashboardPage = lazy(() => import("./Pages/DashboardPage"));
  const UserListPage = lazy(() => import("./Pages/UserListPage"));
  const OrganizationPage = lazy(() => import("./Pages/OrganizationPage"));
  const UserProfilePage = lazy(() => import("./Pages/UserProfilePage"));
  const ProjectListPage = lazy(() => import("./Pages/ProjectListPage"));
  const ProjectDetailsPage = lazy(() => import("./Pages/ProjectsDetailsPage"));
  const SalariesPage = lazy(() => import("./Pages/SalariesPage"));
  const ChatPage = lazy(() => import("./Pages/ChatPage"));
  const UserLocationPage = lazy(() => import("./Pages/UserLocationPage"));
  const OrgRegisterPage = lazy(() => import("./Pages/auth/OrgRegisterPage"));
  const UsersWaitingPage = lazy(() => import("./Pages/UsersWaitingPage"));
  const TaskRecordsPage = lazy(() => import("./Pages/TaskRecordsPage"));

  return (
    <div>
      <Suspense fallback={<Loading />}>
        <AuthContextProvider>
          <Routes>
            <Route path="/" element={<LogIn />} />
            <Route path="/login" element={<LogIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/orgregister" element={<OrgRegisterPage />} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <DashboardPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/userlist"
              element={
                <ProtectedRoute>
                  <UserListPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/userswaiting"
              element={
                <ProtectedRoute>
                  <UsersWaitingPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/organization"
              element={
                <ProtectedRoute>
                  <OrganizationPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/userprofile"
              element={
                <ProtectedRoute>
                  <UserProfilePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/projectlist"
              element={
                <ProtectedRoute>
                  <ProjectListPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/chat"
              element={
                <ProtectedRoute>
                  <ChatPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/projectdetails/:id"
              element={
                <ProtectedRoute>
                  <ProjectDetailsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/salaries"
              element={
                <ProtectedRoute>
                  <SalariesPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/userlocation"
              element={
                <ProtectedRoute>
                  <UserLocationPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="projectdetails/:id/tasks/:taskId/records"
              element={
                <ProtectedRoute>
                  <TaskRecordsPage />
                </ProtectedRoute>
              }
            />
          </Routes>
        </AuthContextProvider>
      </Suspense>
    </div>
  );
}

export default App;
